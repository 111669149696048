<template>
    <div
        class="video-container"
        data-no-touch-simulate="true"
        :class="mode === 'phone' ? 'mobile-video-container' : ''"
    >
        <div class="title" v-if="currentCourse">
            <div class="return-btn" @click="toCoursePage">
                <van-icon name="arrow-left" />
                <span>返回</span>
            </div>
            <div class="course-title">{{ currentCourse.title || '课程' }}</div>
            <TipsNotice v-if="mode === 'computer'" :nStyle="{ width: '50%', height: '32px', position: 'initial' }" />
            <a v-if="isShowCourseware" class="courseware" :href="`/courseware/${currentCourse.courseNo}`">
                <van-icon class="peki-kejian iconfont" />
                <span>课件</span>
            </a>
        </div>
        <TipsNotice
            v-if="mode === 'phone'"
            :nStyle="{ 'border-radius': 0, width: '100%', height: '42px', position: 'initial', 'flex-shrink': 0 }"
        />
        <div
            v-show="isLiveCourse"
            class="live-container"
            :style="`background-image: url(${currentCourse && currentCourse.coverUrl});`"
        >
            <van-button @click="getPlayUrlApi" class="study-btn">进入直播间</van-button>
        </div>
        <div
            v-show="!isLiveCourse"
            ref="video"
            id="dplayerContainer"
            :class="deviceInfo.ios ? 'ios-dplayer' : ''"
            class="dplayer-container"
        />
        <div id="dragProgress" class="progress" v-show="isShowDragAlert">
            <div class="progress-text">{{ dragInfo.current }}/ {{ dragInfo.duration }}</div>
            <div class="progress-container">
                <div :style="`width: ${dragInfo.progress}%`"></div>
            </div>
        </div>
        <div v-if="isShowPositionToast === 1" class="position-toast">
            <span>继续上次播放？</span>
            <span class="btn" @click="onSetVideoPosition">是</span>
            <span class="btn" @click="isShowPositionToast = -1">否</span>
        </div>
        <Evaluate
            :courseInfo="currentCourse"
            :isShowEvaluate="isShowEvaluate"
            v-show="isShowEvaluate"
            @close="isShowEvaluate = false"
        />
    </div>
</template>
<script>
import Evaluate from './Evaluate.vue'
import { coursePosition, getDurationText } from '@/utils/tools.js'
import { addUserAttend, getVideoPlayUrl, getPlayUrl, getHasHandout } from '@/api/studyApi.js'
import { mapGetters } from 'vuex'
import TipsNotice from './TipsNotice.vue'

export default {
    props: ['currentCourse', 'product', 'mode'],
    computed: { ...mapGetters(['deviceInfo']) },
    components: { Evaluate, TipsNotice },
    data() {
        return {
            previewImageUrl: '',
            // 0:默认 1:展示 2:切换中 -1:关闭
            isShowPositionToast: 0,
            isLiveCourse: false,
            isShowCourseware: false,

            isShowEvaluate: false,

            // 滑动
            isShowDragAlert: false,
            dragInfo: { duration: '', current: '', progress: 0, progressTime: 0 },
        }
    },
    watch: {
        currentCourse: function () {
            // 重置视频
            if (this.dp) this.dp.destroy()
            if (this.currentCourse) {
                this.isShowPositionToast = 0
            }
            this.onPlayVideoUrl()
            this.isLiveCourse = false
            const provider = this.currentCourse ? this.currentCourse.provider : ''
            if (provider === 'talkfun' && [0, 1, 2].includes(this.currentCourse.status)) {
                this.isLiveCourse = true
            }
            this.$nextTick(() => {
                this.isShowEvaluate = false
            })

            // 查询课件
            this.getHasHandoutApi()
        },
        isLiveCourse: function () {
            if (this.isLiveCourse && this.player) {
                this.player.destroy()
            }
        },
    },
    mounted() {
        window.DPlayer = require('dplayer')
        document.body.oncontextmenu = function () {
            return false
        }

        // DPlayer 空格的快捷键 有问题
        document.onkeydown = (event) => {
            if (this.dp) {
                if (event.key === ' ' || event.code === 'Space') {
                    this.dp.toggle()
                } else if (event.key === 'ArrowRight' || event.code === 'ArrowRight') {
                    this.dp.seek(this.dp.video.currentTime + 5)
                } else if (event.key === 'ArrowLeft' || event.code === 'ArrowLeft') {
                    this.dp.seek(this.dp.video.currentTime - 5)
                }
            }
        }
    },
    destroyed() {
        window.DPlayer = null
        document.onkeydown = undefined
        document.body.oncontextmenu = null
    },
    methods: {
        toCoursePage() {
            this.$router.push('/course')
        },
        onSetVideoPosition() {
            this.isShowPositionToast = 2
            this.dp.play()
        },
        onPlayVideoUrl() {
            this.$nextTick(() => {
                const { provider, courseNo, status, coverUrl = '', packageNo } = this.currentCourse
                if (provider === 'talkfun' && status === 3) {
                    this.getPlayUrlApi('init')
                } else if (provider === 'video') {
                    getVideoPlayUrl({ packageNo, courseNo }).then((res) => {
                        this.initDPlayer({
                            url: res.data.playUrl,
                            pic: coverUrl,
                        })
                    })
                }
            })
        },
        getPlayUrlApi(type) {
            const { courseNo, status, packageNo, coverUrl = '' } = this.currentCourse
            getPlayUrl({ packageNo, courseNo }).then((res) => {
                if (status === 3 && type === 'init') {
                    if (res.data.videos && res.data.videos.length === 1) {
                        this.initDPlayer(
                            {
                                url: res.data.videos[0]['url'],
                                pic: coverUrl,
                            },
                            res.data.videos[0]['urls']
                        )
                    } else {
                        this.isLiveCourse = true
                    }
                } else {
                    window.location.href = res.data.playUrl
                }
            })
        },
        addUserAttendApi() {
            const { packageId, id, stageId, packageNo, menuId } = this.currentCourse
            const params = {
                packageId,
                courseId: id,
                attendDuration: 5,
                vType: 2,
                speed: this.dp && this.dp.video ? this.dp.video.playbackRate : 1,
            }
            let currentTime = 0
            let duration = 0
            if (this.dp && this.dp.video && this.dp.video.currentTime) {
                currentTime = parseInt(this.dp.video.currentTime)
                duration = parseInt(this.dp.video.duration)
            }
            params.position = currentTime
            if (stageId) params.stageId = stageId
            addUserAttend(params)
            coursePosition(packageNo, [menuId, id, currentTime === duration ? 0 : currentTime])
        },
        initDPlayer(video, quality) {
            const cPosition = coursePosition(this.currentCourse.packageNo)
            if (cPosition[2] && this.isShowPositionToast === 0) {
                this.isShowPositionToast = 1
            } else {
                this.isShowPositionToast = -1
            }
            if (this.dp) this.dp.destroy()
            const params = {
                container: this.$refs.video,
                lang: 'zh-cn',
                hotkey: false,
                playbackSpeed: [0.8, 1, 1.2, 1.5, 2],
            }
            if (quality && quality.length !== 0) {
                const list = []
                quality.forEach((item, index) => {
                    list.push({
                        name: `线路${index + 1}`,
                        url: item,
                        type: 'auto',
                    })
                })
                params.video = {
                    type: 'auto',
                    defaultQuality: 0,
                    pic: video.pic,
                    quality: list,
                }
            } else {
                params.video = {
                    type: 'auto',
                    url: video.url,
                    pic: video.pic,
                }
            }
            this.dp = new window.DPlayer(params)
            this.dp.on('ended', () => {
                this.$emit('playEnd')
                this.addUserAttendApi()
            })
            this.dp.on('pause', () => {
                this.$emit('playEnd')
                this.addUserAttendApi()
            })
            this.dp.on('seeked', () => {
                this.$emit('playEnd')
                this.dp.play()
                this.addUserAttendApi()
            })
            this.dp.on('error', () => {
                if (this.dp.options.video.quality.length > 1) {
                    this.dp.switchQuality(1)
                }
            })
            this.dp.on('play', () => {
                if (this.isShowPositionToast !== 2) {
                    this.isShowPositionToast = -1
                }
                setTimeout(() => {
                    if (this.$refs.video) {
                        const calssName = this.$refs.video.className
                        if (calssName.indexOf('dplayer-hide-controller') === -1) {
                            this.$refs.video.className = `${calssName} dplayer-hide-controller`
                        }
                    }
                }, 600)
            })
            this.dp.on('ratechange', () => {
                if (this.dp.video.playbackRate >= 2 && !window.localStorage.getItem('videoRatechangeDialog')) {
                    this.$dialog.alert({
                        message: '2倍速对网络环境要求较高，如遇卡顿、加载失败等，记得切换回低倍数',
                    })
                    window.localStorage.setItem('videoRatechangeDialog', true)
                }
            })

            let historyTime = 0
            this.dp.on('timeupdate', () => {
                const currentTime = parseInt(this.dp.video.currentTime, 10)
                if (currentTime % 5 === 0 && currentTime !== historyTime) {
                    historyTime = currentTime
                    this.addUserAttendApi()
                }
                if (this.dp.video.currentTime > 0 && this.isShowPositionToast === 2) {
                    const cPosition = coursePosition(this.currentCourse.packageNo)
                    this.dp.seek(cPosition[2])
                    this.isShowPositionToast = -1
                }
                // 课程最后10%显示评价
                const timeNum = parseInt(this.dp.video.duration * 0.1)
                if (this.dp.video.duration - this.dp.video.currentTime < timeNum) {
                    this.isShowEvaluate = true
                }
            })
            if (document.getElementsByClassName('dplayer-setting-loop').length !== 0) {
                const el = document.getElementsByClassName('dplayer-setting-loop')[0]
                el.getElementsByClassName('dplayer-label')[0].innerText = '循环播放'
            }
            this.$nextTick(() => {
                const div = document.getElementById('watermark').cloneNode(true)
                if (this.$refs.video && this.$refs.video.append) {
                    this.$refs.video.append(div)
                }

                // 滑动事件 禁止冒泡
                if (this.deviceInfo.mobile) {
                    const videoWrap = document.getElementsByClassName('dplayer-container')[0]
                    videoWrap.addEventListener('touchstart', this.onTouchstart)
                    videoWrap.addEventListener('touchmove', this.oTouchmove)
                    videoWrap.addEventListener('touchend', this.oTouchend)
                    if (this.$refs.video && this.$refs.video.append) {
                        const div1 = document.getElementById('dragProgress').cloneNode(true)
                        this.$refs.video.append(div1)
                    }
                }
            })
        },
        getHasHandoutApi() {
            this.isShowCourseware = false
            if (this.currentCourse) {
                const { courseNo } = this.currentCourse
                getHasHandout({ courseNos: [courseNo] }).then((res) => {
                    if (res.data && res.data[courseNo]) {
                        this.isShowCourseware = true
                    }
                })
            }
        },
        onTouchstart(event) {
            if (this.dp && this.dp.video.duration) {
                this.touchStartX = event.targetTouches[0]['clientX']
                this.dragCurrentTime = this.dp.video.currentTime || 0
            }
        },
        oTouchmove(event) {
            event.preventDefault()
            if (this.dp && this.dp.video.duration) {
                this.touchMoveX = event.targetTouches[0]['clientX']

                const { duration } = this.dp.video
                this.dragInfo.duration = getDurationText(duration)

                // 2px 对应 1秒
                const gapNum = (this.touchMoveX - this.touchStartX) / 2
                let progressTime = this.dragCurrentTime + gapNum
                if (progressTime < 0) {
                    progressTime = 0
                } else if (progressTime > duration) {
                    progressTime = duration
                }
                this.dragInfo.progress = parseInt((progressTime * 10000) / duration) / 100
                this.dragInfo.current = getDurationText(progressTime)
                this.dragInfo.progressTime = progressTime
                this.isShowDragAlert = true
            }
        },
        oTouchend() {
            if (this.dp && this.dp.video.duration && this.isShowDragAlert) {
                this.dp.seek(this.dragInfo.progressTime)
            }
            this.isShowDragAlert = false
            this.dragInfo = { duration: '', current: '', progress: 0, progressTime: 0 }
            this.touchStartX = 0
            this.touchMoveX = 0
            this.dragCurrentTime = 0
        },
    },
}
</script>
<style scoped lang="scss">
.video-container {
    flex-grow: 1;
    height: 100%;
    background: #242424;
    display: flex;
    flex-direction: column;
    position: relative;
    .title {
        width: 100%;
        height: 54px;
        background: #000000;
        position: relative;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        .return-btn {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #f0941d;
            line-height: 14px;
            margin: 0 20px;
            cursor: pointer;
            .van-icon {
                margin-right: 12px;
            }
        }
        .course-title {
            font-size: 14px;
            color: #ffffff;
            line-height: 14px;
            flex: 1;
        }
        .courseware {
            color: #f0941d;
            padding: 0 12px;
            span {
                font-size: 14px;
            }
        }
    }
    .live-container {
        flex-grow: 1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-position: center center;
        background-size: cover;
        .study-btn {
            width: 120px;
            background: #f0941d;
            color: #ffffff;
            border: none;
            animation: scale 0.7s infinite alternate;
        }
    }
}
.position-toast {
    z-index: 10;
    position: absolute;
    bottom: 50px;
    left: 0;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    height: 42px;
    font-size: 13px;
    padding: 0 12px 0 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    .btn {
        margin-left: 6px;
        color: #f0941d;
        font-weight: 700;
        cursor: pointer;
        padding: 0 4px;
        &:last-child {
            color: #000000;
            font-weight: 400;
        }
    }
}
::v-deep .dplayer-container {
    flex-grow: 1;
    .dplayer-mobile-play {
        padding: 8px;
        background: rgba(0, 0, 0, 0.8);
        border-radius: 50%;
    }
    .dplayer-menu {
        display: none;
    }
    &.dplayer-paused .dplayer-mobile-play {
        padding: 8px 8px 8px 13px;
    }
}
::v-deep .ios-dplayer {
    .dplayer-full-in-icon {
        position: initial !important;
        display: inline-block !important;
    }
    .dplayer-full-icon {
        display: none !important;
    }
}
.progress {
    position: absolute;
    pointer-events: none;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px 20px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    .progress-container {
        height: 4px;
        width: 160px;
        border-radius: 2px;
        background: white;
        overflow: hidden;
        > div {
            background: red;
            height: 4px;
            width: 0;
        }
    }
    .progress-text {
        text-align: center;
        margin-bottom: 6px;
        font-size: 16px;
        color: white;
    }
}
@keyframes scale {
    from {
        transform: scale(0.8);
    }
    to {
        transform: scale(1.2);
    }
}

// 移动端适配
.mobile-video-container {
    .title {
        .return-btn {
            margin: 0 12px;
            flex-shrink: 0;
        }
        .course-title {
            margin-right: 100px;
            flex-grow: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
        }
    }
}
::v-deep .van-action-sheet {
    .course-title {
        display: block;
        text-align: center;
        line-height: 4em;
        font-size: 15px;
    }
}
</style>
